<template lang="pug">
  .col-12.d-flex.justify-content-center
    .card.mt-2
      .card-body
        h1.text-left(style="font-size: 36px") Enter new password
        form(action="" v-on:submit.prevent="setNewPassword()" method="post")
          .form-group
            label(for='password') Password
            .input-group
              input.form-control(:type="[!isVisible ? 'password' : 'text']", name='password', placeholder="Password", v-model='password')
              .input-group-append
                .input-group-text
                  font-awesome-icon(
                    :icon="isVisible ? eyeIcon : eyeSlashIcon" 
                    @click="isVisible = !isVisible"
                  )
          p(v-if="message != ''") {{message}}
            br
            | You will be redirected to log in screen now.
          button(v-if="message == ''").btn.btn-info.btn-lg Set new password
</template>
<script>
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { ROUTE } from '@/enums'

export default {
    computed: {
        eyeIcon: () => faEye,
        eyeSlashIcon: () => faEyeSlash,
    },

    data() {
        return {
            password: '',
            isVisible: false,
            message: '',
        }
    },

    methods: {
        setNewPassword() {
            const url = `/auth/reset/${this.$route.params.token}`

            this.$store.state.backend
                .post(url, {
                    password: this.password,
                })
                .then((ret) => {
                    this.status = ret.data.status
                    this.message = ret.data.message
                    setTimeout(() => {
                        this.$router.push({ name: ROUTE.SIGN_IN })
                    }, 5000)
                })
                .catch((error) => alert('Error (mounted):', error))
        },
    },
}
</script>
